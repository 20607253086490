import React from 'react';

import { Flex, Box, Themed } from 'theme-ui';
import { Container } from '@sprinklr/shared-lib';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';

import { ITEM_TYPE_VS_RENDERER } from './constants';

const FlexTemplate = ({
  headline,
  itemType,
  items,
  topMargin,
  bottomMargin,
}) => {
  const ItemRenderer = ITEM_TYPE_VS_RENDERER[itemType];

  if (!ItemRenderer) return null;

  const padding = { top: topMargin, bottom: bottomMargin };
  const [pt, pb] = useContainerPadding(padding);

  return (
    <Container containerSx={{ pt, pb }}>
      {headline && (
        <Flex
          sx={{
            flexDirection: 'column',
            mx: [0, 2, 3, 6],
            mb: [4, 5, 5],
            textTransform: 'uppercase',
            alignItems: 'center',
          }}
        >
          <Themed.h2 as="h2">{headline}</Themed.h2>
        </Flex>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            flexWrap: 'wrap',
          }}
        >
          {items.map(item => (
            <ItemRenderer item={item} />
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default FlexTemplate;
