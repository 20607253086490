import React from 'react';

import { Box } from 'theme-ui';
import { Avatar } from '@sprinklr/shared-lib';

const AvatarCard = ({ item: { name, image, titleRichText } }) => (
  <Box
    sx={{
      maxWidth: '300px',
    }}
  >
    <Avatar
      name={name}
      titleRichText={titleRichText}
      image={image?.gatsbyImageData}
    />
  </Box>
);

export default AvatarCard;
